<template>
  <v-app id="login_module">
    <v-app-bar
      height="56"
      app
      flat
      class="d-flex align-center border-bottom-light"
      :class="$vuetify.theme.dark ? '' : 'background-white'"
    >
      <a href="https://www.trustline.in" id="login_header_link" target="_blank" rel="noopener noreferrer" class="cursor">
      <img id="login_header_img"
      width="250px"
        src="../assets/images/broker-logo.png"
        alt=""
        style="padding-left: 60px"
      /></a>
    </v-app-bar>
    <v-layout
      row
      wrap
      align-center
      justify-center
      class="opansans-font ma-0"
      style="height: calc(100vh - 56px) !important; margin-top: 56px !important"
    >
      <v-flex
        xs12
        sm12
        md7
        lg7
        xl7
        id="imgPart"
        d-flex
        align-center
        flex-column
        justify-center
        v-if="!$store.state.isMobileView"
      >
        <img src="../assets/images/DashboardBottom.svg" alt="" width="50%" />
        <div class="w-100 text-center pl-3">
        <div class="w-100 d-flex fsize9 justify-center mt-4" id="app_content">
          SEBI Reg. No.- BSE Cash: INB010753631 | BSE F&amp;O: INF010753631 | BSE Currency Derivative : INE010753631 | CDSL: IN-DP-CDSL-154-2001 | NSDL: IN-DP-NSDL-322-2009 | PMS: INP000004268 | NSE Cash: INB230753634 | NSE F&amp;O: INF230753634 | MSEI-SX Cash: INB260753638 | MSEI-SX F&amp;O: INF260753638 | MSEI-SX - CURRENCY : INE260753634 | NSE Currency Derivative: INE230753634 | USE-CURRENCY: INE270753634 | CIN NO-U74899DL1995PLC064753 | Research Analyst Reg. No. INH100000528 | AMFI ARN - 44980 SEBI Reg. No.- NCDEX: INZ000088230 | MCX: INZ0000882304| CIN NO-U51500DL2006PTC154215
        </div>
          </div>
      </v-flex>
      <v-flex xs12 sm12 md5 lg5 xl5 align-center justify-center>
        <v-layout row wrap align-center flex-column justify-center>
          <div
            v-if="$store.state.ssoQuery != ''"
            class="d-flex flex-column align-center pb-2 text-center w-100" id="vendor_name"
          >
            <img v-if="vendorDetails['imageUrl']" :src="vendorDetails['imageUrl']" alt="SSO logo" id="vendor_img"/>
            {{ vendorDetails.appName }}
          </div>
          <div class="rounded custom-Card pa-6 w-344" :class="$vuetify.theme.dark ? 'card-dark' :'card-light'">
            <div id="app_Logo"
              :class="
                this.whichStep == 'authorize'
                  ? 'Img-div text-center'
                  : 'Img-div'
              "
              v-if="
                this.whichStep == 'userId' ||
                this.whichStep == 'Forget' ||
                this.whichStep == 'Unblock' ||
                this.whichStep == 'authorize' ||
                this.whichStep == 'createMpin'
              "
            >
              <img id="app_logo_img"
                width="124px "
                :src="$store.state.loginLogo"
                alt="Logo"
                Lazy="load"
                decode="async"
              />
            </div>
            <!-- UserId div Start -->
            <div v-if="this.whichStep == 'userId'">
              <div class="fsize20 primaryColor font-weight-regular mb-2" id="welcome_tag">
                Welcome to {{ $store.state.projectName }}
              </div>
              <div class="secondaryColor fsize14 mb-4">
                Sign in to your account with valid credentials
              </div>

              <form action="" @submit.prevent="checkUserId" id="user_id_form">
                <div class="mb-3">
                  <label class="fsize14 primaryColor pl-2" id="user_id_form_label">User ID</label>
                  <input id="user_id_form_inp"
                    type="text"
                    v-model="userId"
                    @input="
                      $event.target.value = $event.target.value.toUpperCase()
                    "
                    @keypress="
                      errorMessages = null;
                      keyPressAlphaNumeric($event);
                    "
                    :readonly="userIdLoader"
                    placeholder="Enter your User ID"
                    class="primaryColor input-field fsize14"
                    maxlength="50"
                    ref="user"
                    autofocus
                    autocomplete="off"
                  />
                  <div class="error-msg" :id="`${this.whichStep}_err_msg`">
                    <span v-if="errorMessages">{{ errorMessages }}</span>
                  </div>
                </div>
                <v-btn id="user_id_btn"
                  depressed
                  :color="$store.state.buttonThemeColor"
                  :loading="userIdLoader"
                  :disabled="userIdLoader"
                  @click="checkUserId"
                  class="flat w-100 text-capitalize fsize14 white--text"
                  >Next</v-btn
                >
              </form>
              <div
                class="
                  mt-2
                  mb-4
                  d-flex
                  justify-space-between
                  fsize12
                  letter-space
                  linkColor
                "
              >
                <span @click="checkIsMpinOrPasswordForget('Forget')" id="user_id_forgot_pass_link"
                  >Forgot Password?</span
                >
                <span @click="checkIsMpinOrPasswordForget('Unblock')" id="user_id_unblock_pass_link"
                  >Unblock Account</span
                >
              </div>
              <div class="d-flex justify-center fsize12 letter-space">
                <span class="primaryColor" id="new_user_create"
                  >New to {{ $store.state.projectLink }}?</span
                >
                <a id="new_user_create_link"
                  class="linkColor pl-1 text-decoration-none"
                  :href="$store.state.createAccount"
                  target="_blank"
                  >Create an account</a
                >
              </div>
            </div>
            <!-- UserId div End  -->
            <div
              v-if="this.whichStep == 'M-Pin' || this.whichStep == 'Password' || this.whichStep == 'OTP'"
            >
              <div class="Img-avater text-center mb-4">
               
                <!-- <v-avatar color="#ffffff" size="90" tile> -->
                  <div class="d-flex align-center justify-center" style="height: 90px">
                     <img id="logo_img"
                src="../assets/images/sidebarLogo.png"
                alt="Logo"
                Lazy="load"
                decode="async"
              /> 
                  </div>
              <!-- </v-avatar> -->
                <div class="fsize21 mt-4" id="user_id_valid">{{ getUserId }}</div>
                <div id="switch_acc_link"
                  class="d-flex justify-center fsize10 letter-space linkColor"
                  @click="ChangeUser"
                >
                  Switch Account
                </div>
              </div>
              <form action="" @submit.prevent="checkVaildPassOrMpin" :id="`${this.whichStep}_form`">
                <div class="mb-3">
                  <label class="fsize12 primaryColor l-height-24 pl-2" :id="`${this.whichStep}_label`"> 
                     {{ this.whichStep == 'OTP' ? '6 - Digit OTP' : this.whichStep }}</label
                  >
                  <div
                    class="
                      pswborder
                      rounded
                      d-flex
                      align-center
                      justify-space-between
                      w-100
                      h-40
                    "
                  >
                    <span class="inputWithImg cursor">
                      <input :id="`${this.whichStep}_inp`"
                        ref="mpin"
                        :placeholder="this.whichStep == 'OTP' ? 'Enter your 6 - Digit OTP' : `Enter your ${this.whichStep}`"
                        v-model="newPassAndMpin"
                        :type="passwordFieldTypePassOrMpin"
                        :maxlength="this.whichStep == 'M-Pin' ||  this.whichStep == 'OTP' ? '6' : '50'"
                        autocomplete="off"
                        @keypress="keyPressNumeric($event)"
                        class="
                          w-100
                          border-none
                          h-40
                          outline-none
                          rounded
                          py-2
                          px-4
                          fsize14
                          primaryColor
                        "
                        required
                      />
                    </span>
                    <span
                      class="rounded d-flex align-center h-40"
                      @click="toggleFieldTextTypePassOrMpin()"
                    >
                      <customIcon
                        class="rounded h-40 InputWithImg cursor pt-2 pr-2"
                        :name="passOrMpinShowOrHide ? 'eyeopen' : 'eyeclose'"
                        :width="'24'"
                        :height="'24'"
                        :color="$store.state.iconBlackColourCode"
                    /></span>
                  </div>
                  <div class="error-msg" :id="`${this.whichStep}_err_msg`">
                    <span v-if="passwordAndMpinErrorMessage">{{
                      passwordAndMpinErrorMessage
                    }}</span>
                  </div>
                </div>
                <v-btn :id="`${this.whichStep}_btn`"
                  depressed
                  :color="$store.state.buttonThemeColor"
                  :loading="passOrMpinLoader"
                  :disabled="passOrMpinLoader"
                  @click="checkVaildPassOrMpin"
                  class="flat w-100 text-capitalize fsize14 white--text"
                 >{{this.whichStep == 'OTP' ? 'Login' : 'Next'}}</v-btn
                >
              </form>
               <div
                class="
                  mt-2
                  mb-4
                  d-flex
                  
                  fsize12
                  letter-space
                  linkColor
                "
                :class="this.whichStep != 'OTP' ? 'justify-space-between' : 'justify-end'"
              >
                <span v-if="this.whichStep != 'OTP'" @click=" checkIsMpinOrPasswordForget('Forget')" :id="`${this.whichStep}_forgot_link`">
                  {{
                     this.whichStep == "Password"
                      ? "Forgot Password?"
                      : "Forgot M-Pin?"
                  }}</span
                >
                <span v-if="this.whichStep != 'OTP'" @click="checkIsMpinOrPasswordForget('Unblock')" :id="`${this.whichStep}_unblock`"
                  >Unblock Account</span
                >
                <span v-else @click="getTimer  == 'Resend OTP' ? resendOtp() : ''" :id="`${this.whichStep}_timer`"
                  >{{getTimer}}</span
                >
              </div>
              <div class="d-flex justify-center fsize12 letter-space" :id="`${this.whichStep}_acc_create`">
                <span class="primaryColor"
                  >New to {{ $store.state.projectLink }}?</span
                >
                <a :id="`${this.whichStep}_acc_create_link`"
                  class="linkColor pl-1 text-decoration-none"
                  :href="$store.state.createAccount"
                  target="_blank"
                  >Create an account</a
                >
              </div>
            </div>

            <div
              v-if="this.whichStep == '2FA' || this.whichStep == 'createMpin'"
            >
              <div
                :class="
                  this.whichStep == 'createMpin'
                    ? 'fsize20 primaryColor font-weight-regular mb-4'
                    : 'fsize20 primaryColor font-weight-regular mb-2'
                "
                :id="`${this.whichStep}_label_head`"
              >
                {{
                  this.whichStep == "2FA" ? "Security Questions" : "Create Mpin"
                }}
              </div>

              <form action="" @keyup.enter="vaidateAnswer" :id="`${this.whichStep}_form`">
                <div class="mb-3">
                  <div class="fsize12 secondaryColor l-height-24 pl-2" :id="`${this.whichStep}_label`">
                    {{
                      this.whichStep == "2FA"
                        ? this.userQuestions[0]["Ques"]
                        : "New M-Pin"
                    }}
                  </div>
                  <div
                    class="
                      pswborder
                      rounded
                      d-flex
                      align-center
                      justify-space-between
                      w-100
                      h-40
                    "
                  >
                    <span class="inputWithImg cursor">
                      <input :id="`${this.whichStep}_inp`"
                        ref="twofa"
                        class="
                          w-100
                          border-none
                          h-40
                          outline-none
                          rounded
                          py-2
                          px-4
                          fsize14
                          primaryColor
                        "
                        placeholder="Enter your Answer"
                        :maxlength="this.whichStep == '2FA' ? '50' : '6'"
                        autocomplete="off"
                        v-model="secQuesOneAndNewMpin"
                        :type="isSecQues1"
                        autofocus
                        @keypress="keyPressNumeric($event)"
                      />
                    </span>
                    <span
                      class="rounded d-flex align-center h-40"
                      @click="toggleFieldTextTypeSecQ1()"
                    >
                      <customIcon
                        class="rounded h-40 InputWithImg cursor pt-2 pr-2"
                        :name="isSecQues1ShowOrHide ? 'eyeopen' : 'eyeclose'"
                        :width="'24'"
                        :height="'24'"
                        :color="$store.state.iconBlackColourCode"
                    /></span>
                  </div>
                  <div class="error-msg" :id="`${this.whichStep}_err_msg`">
                    <span v-if="securityAndCreateMpinErrorMessage1">{{
                      securityAndCreateMpinErrorMessage1
                    }}</span>
                  </div>
                  <div class="fsize12 secondaryColor l-height-24 pl-2" :id="`${this.whichStep}_ques`">
                    {{
                      this.whichStep == "2FA"
                        ? this.userQuestions[1]["Ques"]
                        : "Confirm M-Pin"
                    }}
                  </div>
                  <div
                    class="
                      pswborder
                      rounded
                      d-flex
                      align-center
                      justify-space-between
                      w-100
                      h-40
                    "
                  >
                    <span class="inputWithImg cursor">
                      <input :id="`${this.whichStep}_ques_inp`"
                        class="
                          w-100
                          border-none
                          h-40
                          outline-none
                          rounded
                          py-2
                          px-4
                          fsize14
                          primaryColor
                        "
                        placeholder="Enter your Answer"
                        :maxlength="this.whichStep == '2FA' ? '50' : '6'"
                        autocomplete="off"
                        v-model="secQuesTwoAndConMpin"
                        outlined
                        :type="isSecQues2"
                        @keypress="keyPressNumeric($event)"
                      />
                    </span>
                    <span
                      class="rounded d-flex align-center h-40"
                      @click="toggleFieldTextTypeSecQ2()"
                    >
                      <customIcon
                        class="rounded h-40 InputWithImg cursor pt-2 pr-2"
                        :name="isSecQues2ShowOrHide ? 'eyeopen' : 'eyeclose'"
                        :width="'24'"
                        :height="'24'"
                        :color="$store.state.iconBlackColourCode"
                    /></span>
                  </div>
                  <div class="negativeColor fsize12 h-12" :id="`${this.whichStep}_err_msg`">
                    <span
                      v-if="this.whichStep == '2FA' && questsErrorMessage != ''"
                      >{{ questsErrorMessage }}</span
                    >
                    <span v-else-if="securityAndCreateMpinErrorMessage2">{{
                      securityAndCreateMpinErrorMessage2
                    }}</span>
                  </div>
                </div>
                <v-btn :id="`${this.whichStep}_btn`"
                  depressed
                  :color="$store.state.buttonThemeColor"
                  :loading="createOr2faLoader"
                  :disabled="createOr2faLoader"
                  @click="vaidateAnswer"
                  class="flat w-100 text-capitalize fsize14 white--text"
                  >{{ this.whichStep == "2FA" ? "Login" : "Create" }}</v-btn
                >
              </form>
              <div
                class="
                  mt-2
                  mb-4
                  d-flex
                  justify-space-between
                  fsize12
                  letter-space
                  linkColor
                "
              >
                <span :id="`${this.whichStep}_forgetsec_ques`">Forgot Security Questions</span>
                <span @click="checkIsMpinOrPasswordForget('Unblock')" :id="`${this.whichStep}_unblock`"
                  >Unblock Account</span
                >
              </div>
              <div class="d-flex justify-center fsize12">
                <span class="primaryColor" :id="`back_to_span`">Back to</span>
                <span class="linkColor pl-1" @click="clearLocal" :id="`back_to_span`">Login</span>
              </div>
            </div>

            <div
              v-if="this.whichStep == 'Forget' || this.whichStep == 'Unblock'"
            >
              <div class="fsize20 primaryColor font-weight-regular mb-2" :id="`${this.whichStep}_label_head`">
                {{
                  this.whichStep == "Forget"
                    ? "Account Recovery"
                    : "Account Unblock"
                }}
              </div>
              <div class="secondaryColor fsize14 mb-4" :id="`${this.whichStep}_info`">
                {{
                  this.whichStep == "Forget"
                    ? "Reset your password with valid Data."
                    : "Unblock your " +
                      $store.state.projectLink +
                      " Account with vaild data."
                }}
              </div>

              <form action="" @keyup.enter="vailDateAccount" :id="`${this.whichStep}_form`">
                <div class="mb-3">
                  <label class="fsize14 primaryColor pl-2" :id="`${this.whichStep}_user_Id`">User ID</label>
                  <input :id="`${this.whichStep}_user_Id_inp`"
                    ref="unblock"
                    type="text"
                    v-model="forgetUser"
                    placeholder="Enter your User ID"
                    class="primaryColor input-field fsize14"
                    maxlength="50"
                    @input="forgetUser = forgetUser.toUpperCase()"
                    autofocus
                    autocomplete="off"
                  />
                  <div class="error-msg" :id="`${this.whichStep}_user_Id_err_msg`">
                    <span v-if="forgetUserErrorMessage">{{
                      forgetUserErrorMessage
                    }}</span>
                  </div>
                <div v-if="this.whichStep == 'Unblock'">
                  <label class="fsize14 primaryColor pl-2" id="email_label">Email</label>
                  <input id="email_inp"
                    type="text"
                    v-model="email"
                    placeholder="Enter your Email Id"
                    class="primaryColor input-field fsize14"
                    autocomplete="off"
                  />
                  <div class="error-msg" id="email_err_msg">
                    <span v-if="emailErrorMessage">{{ emailErrorMessage }}</span>
                  </div>
                </div>
                  <label class="fsize14 primaryColor pl-2" id="pan_number_label">PAN Number</label>
                  <input id="pan_number_inp"
                    type="text"
                    v-model="panNumber"
                    maxlength="10"
                    placeholder="Enter your PAN Number"
                    class="primaryColor input-field fsize14"
                    @input="panNumber = panNumber.toUpperCase()"
                    autocomplete="off"
                  />
                  <div class="error-msg" id="pan_number_err_msg">
                    <span v-if="panErrorMessage">{{ panErrorMessage }}</span>
                  </div>
                  <div class="negativeColor fsize12 h-12" id="forgot_unblock_err_msg">
                    <span
                      v-if="
                        (this.whichStep == 'Forget' ||
                          this.whichStep == 'Unblock') &&
                        unblockErrorMessage != ''
                      "
                      >{{ unblockErrorMessage }}</span
                    >
                  </div>
                </div>
                <v-btn :id="`${this.whichStep}_btn`"
                  depressed
                  :color="$store.state.buttonThemeColor"
                  @click="vailDateAccount"
                  :loading="forgetOrUnblockLoader"
                  :disabled="forgetOrUnblockLoader"
                  class="flat w-100 text-capitalize fsize14 white--text"
                  >{{ this.whichStep == "Forget" ? "Reset" : "Unblock" }}</v-btn
                >
              </form>

              <div class="d-flex justify-center fsize12 mt-4">
                <span class="primaryColor" :id="`back_to_span`">Back to</span>
                <span class="linkColor pl-1" @click="clearLocal" :id="`back_to_span`">Login</span>
              </div>
            </div>
            <div v-if="this.whichStep == 'authorize'">
              <div :id="`${this.whichStep}_name`"
                class="
                  fsize20
                  primaryColor
                  font-weight-regular
                  mb-2
                  text-center
                "
              >
                Authorize {{ $store.state.projectName }}
              </div>
              <div class="text-center fsize14 text-center" :id="`${this.whichStep}_info`">
                Permission required by the app
              </div>
              <ul class="fsize14 ml-4 pt-4 mb-8 primaryColor" :id="`${this.whichStep}_info_ul`">
                <li>Access holding and positions portfolio</li>
                <li>Place, modify, and cancel otders</li>
                <li>View your account balance and margins</li>
                <li>View your profile details</li>
              </ul>
              <v-btn :id="`${this.whichStep}_btn`"
                depressed
                :color="$store.state.buttonThemeColor"
                @click="vaildateAuthorize"
                :loading="authorizeLoader"
                :disabled="authorizeLoader"
                class="flat w-100 text-capitalize fsize14 white--text"
                >Authorize</v-btn
              >
            </div>

            <div v-if="this.whichStep == 'reset'" >
              <div class="fsize20 primaryColor font-weight-regular mb-4" :id="`${this.whichStep}_card`">
                Reset Password
              </div>

              <form action="" @keyup.enter="vailDateResetPassword">
                <div class="mb-3">
                  <label class="fsize12 secondaryColor l-height-24 pl-2" :id="`${this.whichStep}_current_pass_label`">
                    Current Password</label
                  >
                  <div
                    class="
                      pswborder
                      rounded
                      d-flex
                      align-center
                      justify-space-between
                      w-100
                      h-40
                    "
                  >
                    <span class="inputWithImg cursor">
                      <input :id="`${this.whichStep}_current_pass_inp`"
                        ref="reset"
                        class="
                          w-100
                          border-none
                          h-40
                          outline-none
                          rounded
                          py-2
                          px-4
                          fsize14
                          primaryColor
                        "
                        placeholder="Enter Current Password"
                        maxlength="50"
                        autocomplete="off"
                        v-model="currentPass"
                        :type="isCurrentPassIcon"
                        autofocus
                      />
                    </span>
                    <span
                      class="rounded d-flex align-center h-40"
                      @click="toggleFieldTextTypeCurrentPass()"
                    >
                      <customIcon
                        class="rounded h-40 InputWithImg cursor pt-2 pr-2"
                        :name="curentPassShowOrHide ? 'eyeopen' : 'eyeclose'"
                        :width="'24'"
                        :height="'24'"
                        :color="$store.state.iconBlackColourCode"
                    /></span>
                  </div>
                  <div class="error-msg">
                    <span v-if="currentPassErrorMessage">{{
                      currentPassErrorMessage
                    }}</span>
                  </div>

                  <label class="fsize12 secondaryColor l-height-24 pl-2" :id="`${this.whichStep}_new_pass_label`">
                    New Password</label
                  >
                  <div
                    class="
                      pswborder
                      rounded
                      d-flex
                      align-center
                      justify-space-between
                      w-100
                      h-40
                    "
                  >
                    <span class="inputWithImg cursor">
                      <input :id="`${this.whichStep}_new_pass_inp`"
                        class="
                          w-100
                          border-none
                          h-40
                          outline-none
                          rounded
                          py-2
                          px-4
                          fsize14
                          primaryColor
                        "
                        placeholder="Enter New Password"
                        maxlength="50"
                        autocomplete="off"
                        v-model="newPass"
                        :type="isNewPassIcon"
                      />
                    </span>
                    <span
                      class="rounded d-flex align-center h-40"
                      @click="toggleFieldTextTypeNewPass()"
                    >
                      <customIcon
                        class="rounded h-40 InputWithImg cursor pt-2 pr-2"
                        :name="newPassShowOrHide ? 'eyeopen' : 'eyeclose'"
                        :width="'24'"
                        :height="'24'"
                        :color="$store.state.iconBlackColourCode"
                    /></span>
                  </div>
                  <div class="error-msg">
                    <span v-if="newPassErrorMessage">{{
                      newPassErrorMessage
                    }}</span>
                  </div>
                  <label class="fsize12 secondaryColor l-height-24 pl-2" :id="`${this.whichStep}_confirm_pass_label`">
                    Confirm Password</label
                  >
                  <div
                    class="
                      pswborder
                      rounded
                      d-flex
                      align-center
                      justify-space-between
                      w-100
                      h-40
                    "
                  >
                    <span class="inputWithImg cursor">
                      <input :id="`${this.whichStep}_confirm_pass_inp`"
                        class="
                          w-100
                          border-none
                          h-40
                          outline-none
                          rounded
                          py-2
                          px-4
                          fsize14
                          primaryColor
                        "
                        placeholder="Enter Confirm Password"
                        maxlength="50"
                        autocomplete="off"
                        v-model="confirmPass"
                        :type="isConfirmPassIcon"
                      />
                    </span>
                    <span
                      class="rounded d-flex align-center h-40"
                      @click="toggleFieldTextTypeConfirmPass()"
                    >
                      <customIcon
                        class="rounded h-40 InputWithImg cursor pt-2 pr-2"
                        :name="confirmPassShowOrHide ? 'eyeopen' : 'eyeclose'"
                        :width="'24'"
                        :height="'24'"
                        :color="$store.state.iconBlackColourCode"
                    /></span>
                  </div>
                  <div class="error-msg">
                    <span v-if="confirmPassErrorMessage">{{
                      confirmPassErrorMessage
                    }}</span>
                  </div>
                </div>
                <v-btn :id="`${this.whichStep}_reset_btn`"
                  depressed
                  :color="$store.state.buttonThemeColor"
                  @click="vailDateResetPassword"
                  :loading="resetLoader"
                  :disabled="resetLoader"
                  class="flat w-100 text-capitalize fsize14 white--text"
                  >Reset</v-btn
                >
              </form>

              <div class="d-flex justify-center fsize12 mt-4">
                <span class="primaryColor" :id="`back_to_span`">Back to</span>
                <span class="linkColor pl-1" @click="clearLocal" :id="`back_to_span`">Login</span>
              </div>
            </div>
            <div v-if="this.whichStep == 'Reset 2FA'">
              <div
                class="
                  fsize20
                  primaryColor
                  font-weight-regular
                  mb-4
                  text-center
                " :id="`${this.whichStep}__label_title`"
              >
                Reset 2FA
              </div>
              <div class="fsize14 mb-2 secondaryColor text-center" :id="`${this.whichStep}_label_info`">
                Please select a Any Five Questions
              </div>

              <form action="" @keyup.enter="vaildateResetQues" :id="`${this.whichStep}_info`">
                <div class="w-100 mb-4 vh-70 ofy-auto">
                  <div v-for="(item, index) in this.userQuestions" :key="index">
                    <div class="row ma-0 py-2 pr-2 align-center">
                      <label class="fsize12 secondaryColor cursor l-height-24">
                        <input
                          type="checkbox"
                          ref="qes0"
                          v-model="item.checked"
                          :value="item.checkedValue"
                          @click="checkBox($event, item)"
                          class="mr-2"
                        />
                        {{ item.ques }}</label
                      >
                      <div
                        class="
                          pswborder
                          rounded
                          d-flex
                          align-center
                          justify-space-between
                          w-100
                          h-40
                        "
                      >
                        <span class="inputWithImg cursor">
                          <input
                            :disabled="!item.checked"
                            v-model="item.value"
                            :type="item.passwordFieldType"
                            minlength="1"
                            maxlength="20"
                            class="
                              w-100
                              border-none
                              h-40
                              outline-none
                              rounded
                              py-2
                              px-4
                              fsize14
                              primaryColor
                            "
                            required
                          />
                        </span>
                        <span
                          class="rounded d-flex align-center h-40"
                          @click="toggleFieldTextType(item)"
                        >
                          <customIcon
                            class="rounded h-40 InputWithImg cursor pt-2 pr-2"
                            :name="item.fieldTextType ? 'eyeopen' : 'eyeclose'"
                            :width="'24'"
                            :height="'24'"
                            :color="$store.state.iconBlackColourCode"
                        /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <v-btn
                depressed
                :color="$store.state.buttonThemeColor"
                @click="vaildateResetQues"
                :loading="reset2faLoader"
                :disabled="reset2faLoader"
                class="flat w-100 text-capitalize fsize14 white--text"
                >Save</v-btn
              >
            </div>
          </div>
          <div class="w-100 text-center pl-3">
            <div class="w-100 fsize12 d-flex fsize12 justify-center my-2" id="trust_sec_ltd">
                  Trustline Securities Ltd   
            </div>
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import errorHandling from "../store/Services/errorHandling";
import customIcon from "../components/customIcon.vue";
export default {
  components: {
    customIcon,
  },
  data: () => ({
    passwordShow: false,

    forgetUser: "",
    panNumber: "",
    errorMessages: "",
    selectedArray: [],
    counter: 0,

    // resetPass field
    isCurrentPassIcon: "password",
    curentPassShowOrHide: false,
    isNewPassIcon: "password",
    newPassShowOrHide: false,
    isConfirmPassIcon: "password",
    confirmPassShowOrHide: false,
    confirmPass: "",
    confirmPassErrorMessage: "",
    newPass: "",
    newPassErrorMessage: "",
    currentPass: "",
    currentPassErrorMessage: "",

    // passOrMpinicon
    newPassAndMpin: "",
    passwordFieldTypePassOrMpin: "password",
    passOrMpinShowOrHide: false,

    // 2FA field  // sec-q
    isSecQues1: "password",
    isSecQues2: "password",
    isSecQues1ShowOrHide: false,
    isSecQues2ShowOrHide: false,
    secQuesOneAndNewMpin: "",
    secQuesTwoAndConMpin: "",
    userPassword:'',

    email: '',
    emailErrorMessage:''
  }),

  methods: {
     resendOtp(){
      this.$store.commit('authentication/setIsResend' , true)
      var userId = this.getUserId;
          var password = this.userPassword;
      this.newPassAndMpin = null
          this.$store.dispatch("authentication/encryptPassword", {
            userId,
            password,
          });
    },
    toggleFieldTextTypePassOrMpin() {
      this.passOrMpinShowOrHide = !this.passOrMpinShowOrHide;
      this.passwordFieldTypePassOrMpin = this.passOrMpinShowOrHide
        ? "text"
        : "password";
    },

    toggleFieldTextTypeSecQ1() {
      this.isSecQues1ShowOrHide = !this.isSecQues1ShowOrHide;
      this.isSecQues1 = this.isSecQues1ShowOrHide ? "text" : "password";
    },
    toggleFieldTextTypeSecQ2() {
      this.isSecQues2ShowOrHide = !this.isSecQues2ShowOrHide;
      this.isSecQues2 = this.isSecQues2ShowOrHide ? "text" : "password";
    },

    resetPassAndMpin() {
      this.passOrMpinShowOrHide = false;
      this.newPassAndMpin = null;
      this.passwordFieldTypePassOrMpin = "password";
      this.$store.commit("authentication/setPasswordAndMpinErrorMessage", "");
    },
    reset2FA() {
      this.secQuesTwoAndConMpin = null;
      this.secQuesOneAndNewMpin = null;
      this.isSecQues1ShowOrHide = false;
      this.isSecQues2ShowOrHide = false;
      (this.isSecQues1 = "password"),
        (this.isSecQues2 = "password"),
        this.$store.commit("authentication/setQuesAndMpinErrorMessageTwo", "");
      this.$store.commit("authentication/setQuesAndMpinErrorMessageOne", "");
    },
    toggleFieldTextTypeCurrentPass() {
      this.curentPassShowOrHide = !this.curentPassShowOrHide;
      this.isCurrentPassIcon = this.curentPassShowOrHide ? "text" : "password";
    },
    toggleFieldTextTypeNewPass() {
      this.newPassShowOrHide = !this.newPassShowOrHide;
      this.isNewPassIcon = this.newPassShowOrHide ? "text" : "password";
    },
    toggleFieldTextTypeConfirmPass() {
      this.confirmPassShowOrHide = !this.confirmPassShowOrHide;
      this.isConfirmPassIcon = this.confirmPassShowOrHide ? "text" : "password";
    },

    resetPasswordField() {
      this.currentPass = null;
      this.newPass = null;
      this.confirmPass = null;
      this.curentPassShowOrHide = false;
      this.newPassShowOrHide = false;
      this.confirmPassShowOrHide = false;
      this.currentPassErrorMessage = "";
      this.newPassErrorMessage = "";
      this.newPassErrorMessage = "";
      this.isConfirmPassIcon = "password";
      this.isNewPassIcon = "password";
      this.isCurrentPassIcon = "password";
    },
    checkUserId() {
      if (!!this.getUserId) {
        let userfetch = {
          userId: this.getUserId,
        };
        this.$store.dispatch("authentication/getUserId", userfetch);
        this.$store.commit("authentication/setPasswordAndMpinErrorMessage", "");
        this.resetPassAndMpin();
      } else {
        this.errorMessages = "Please Enter Your UserID";
      }
    },
    checkVaildPassOrMpin() {
      if (!!this.newPassAndMpin) {
        if (this.whichStep == "M-Pin") {
          let userfetch;
          this.$store.state.ssoQuery == ""
            ? (userfetch = {
                mpin: this.newPassAndMpin,
                userId: this.getUserId,
              })
            : (userfetch = {
                mpin: this.newPassAndMpin,
                userId: this.getUserId,
                callBackUrl: this.$store.state.ssoQuery.redirectUrl,
                loginMode: this.$store.state.ssoQuery.mode,
                vendor: this.$store.state.ssoQuery.vendor,
              });
          this.newPassAndMpin.length == 6
            ? this.$store.dispatch("authentication/callMpin", userfetch)
            : this.$store.commit(
                "authentication/setPasswordAndMpinErrorMessage",
                "Please Enter 6 Digits"
              );
          this.newPassAndMpin.length == 6 ? this.resetPassAndMpin() : "";
        }else if(this.whichStep == "OTP"){
          let userfetch;
          this.$store.state.ssoQuery == ""
            ? (userfetch = {
                otp: this.newPassAndMpin,
                userId: this.getUserId,
              })
            : (userfetch = {
                otp: this.newPassAndMpin,
                userId: this.getUserId,
                callBackUrl: this.$store.state.ssoQuery.redirectUrl,
                loginMode: this.$store.state.ssoQuery.mode,
                vendor: this.$store.state.ssoQuery.vendor,
              });
              userfetch.loginType = "WEB"
          this.newPassAndMpin.length == 6
            ? this.$store.dispatch("authentication/validateOtp", userfetch)
            : this.$store.commit(
                "authentication/setPasswordAndMpinErrorMessage",
                "OTP should be 6 Digit"
              );
        } else {
          var userId = this.getUserId;
          var password = this.newPassAndMpin;
          this.userPassword = this.newPassAndMpin
          this.$store.dispatch("authentication/encryptPassword", {
            userId,
            password,
          });

          this.reset2FA();
        }
      } else {
        this.$store.commit(
          "authentication/setPasswordAndMpinErrorMessage",
          this.whichStep == "M-Pin"
            ? "Please Enter your Mpin"
            : "Please Enter your Password"
        );
      }
    },
    clearLocal() {
      localStorage.clear();
      this.$store.commit("authentication/setWhichStep", "userId");
      this.$store.commit("authentication/setUserId", "");
      this.newPassAndMpin = null;
      this.secQuesOneAndNewMpin = null;
      this.secQuesTwoAndConMpin = null;
      this.$nextTick(() => {
        this.$refs.user.focus();
      });
    },
    vaidateAnswer() {
      if (!!this.secQuesTwoAndConMpin && !!this.secQuesOneAndNewMpin) {
        let mapObject;
        this.$store.state.ssoQuery == ""
          ? (mapObject = {
              answer1: this.secQuesOneAndNewMpin,
              answer2: this.secQuesTwoAndConMpin,
              userId: this.getUserId,
              sCount: this.userQuestions.length.toString(),
              sIndex:
                this.userQuestions[0]["index"] +
                "|" +
                this.userQuestions[1]["index"],
            })
          : (mapObject = {
              answer1: this.secQuesOneAndNewMpin,
              answer2: this.secQuesTwoAndConMpin,
              userId: this.getUserId,
              sCount: this.userQuestions.length.toString(),
              sIndex:
                this.userQuestions[0]["index"] +
                "|" +
                this.userQuestions[1]["index"],
              callBackUrl: this.$store.state.ssoQuery.redirectUrl,
              loginMode: this.$store.state.ssoQuery.mode,
              vendor: this.$store.state.ssoQuery.vendor,
            });

        this.whichStep == "2FA"
          ? this.$store.dispatch("authentication/vailDateSecQues", mapObject)
          : "";

        let json = {
          userId: this.getUserId,
          mpin: this.secQuesTwoAndConMpin,
        };
        this.whichStep == "createMpin" && this.secQuesOneAndNewMpin.length != 6
          ? this.$store.commit(
              "authentication/setQuesAndMpinErrorMessageOne",
              "New Mpin should be 6 Digits"
            )
          : "";
        this.whichStep == "createMpin" && this.secQuesTwoAndConMpin.length != 6
          ? this.$store.commit(
              "authentication/setQuesAndMpinErrorMessageTwo",
              "Confirm Mpin should be 6 Digits"
            )
          : "";
        this.whichStep == "createMpin" &&
        this.secQuesTwoAndConMpin.length == 6 &&
        this.secQuesOneAndNewMpin.length == 6 &&
        this.secQuesOneAndNewMpin != this.secQuesTwoAndConMpin
          ? this.$store.commit(
              "authentication/setQuesAndMpinErrorMessageTwo",
              "Confirm mpin does not match new Mpin"
            )
          : "";
        this.whichStep == "createMpin" &&
        this.secQuesTwoAndConMpin.length == 6 &&
        this.secQuesOneAndNewMpin.length == 6 &&
        this.secQuesOneAndNewMpin == this.secQuesTwoAndConMpin
          ? this.$store.dispatch("authentication/createMpin", json)
          : "";
        this.resetPasswordField();
      } else {
        !!this.secQuesOneAndNewMpin
          ? ""
          : this.$store.commit(
              "authentication/setQuesAndMpinErrorMessageOne",
              this.whichStep == "2FA"
                ? "Please Enter your Answer"
                : "New Mpin is Cannot Empty"
            );
        !!this.secQuesTwoAndConMpin
          ? ""
          : this.$store.commit(
              "authentication/setQuesAndMpinErrorMessageTwo",
              this.whichStep == "2FA"
                ? "Please Enter your Answer"
                : "Confirm Mpin is Cannot Empty"
            );
      }
    },
    checkIsMpinOrPasswordForget(key) {
      this.newPassAndMpin = null;
      this.forgetUser = null;
      this.panNumber = null;
      this.email = null
      this.emailErrorMessage = ''
      this.$store.commit("authentication/setForgetUserErrorMessage", "");
      this.$store.commit("authentication/setPanErrorMessage", "");
      this.$store.commit("authentication/setPasswordAndMpinErrorMessage", "");
      if (this.whichStep == "M-Pin") {
        this.resetPassAndMpin();
        this.$store.commit("authentication/setWhichStep", "Password");
        this.$refs.mpin.focus();
      }else if (key == 'Forget'){
        window.open('https://nestrest.trustline.in/Express5/forgotPwdExtlogin#', '_blank')
      } else {
        this.$store.commit("authentication/setWhichStep", key);
      }
    },

    ChangeUser() {
      this.$store.commit("authentication/setWhichStep", "userId");
      this.$store.commit("authentication/setUserId", "");
      localStorage.setItem("currentUser", JSON.stringify(""));
      this.newPassAndMpin = null;
      requestAnimationFrame(() => {
        this.$refs.user.focus();
      });
      this.resetPassAndMpin();
    },

    keyPressNumeric(event) {
      this.$store.commit("authentication/setPasswordAndMpinErrorMessage", "");
      this.$store.commit("authentication/setQuesAndMpinErrorMessageOne", "");
      this.$store.commit("authentication/setQuesAndMpinErrorMessageTwo", "");
      this.$store.commit("authentication/setQuestionsErrorMessage", "");
      if (
        event.keyCode != 13 &&
        (this.whichStep == "M-Pin" || this.whichStep == "createMpin" || this.whichStep == "OTP")
      ) {
        var inp = String.fromCharCode(event.keyCode);
        if (/[0-9]/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    },

    vailDateAccount() { 
      var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
      if ((this.whichStep == "Forget" && !!this.forgetUser && !!this.panNumber && regex.test(this.panNumber)) || (this.whichStep == "Unblock" && !!this.forgetUser && !!this.panNumber && regex.test(this.panNumber) && !!this.email &&  /.+@.+\..+/.test(this.email))) {
        let unblock = {
          pan: this.panNumber,
          email: this.email,
          userId: this.forgetUser.toUpperCase(),
        };

        let forget = {
          userId: this.forgetUser.toUpperCase(),
          pan: this.panNumber,
          email: "",
          dob: "",
        };
        this.whichStep == "Forget"
          ? this.$store.dispatch("authentication/forgetPassword", forget)
          : this.$store.dispatch("authentication/unblockAccount", unblock);
        this.resetPassAndMpin();
      } else {
        !!this.forgetUser
          ? ""
          : this.$store.commit(
              "authentication/setForgetUserErrorMessage",
              "Please Enter your UserID"
            );
        !!this.panNumber && regex.test(this.panNumber)
          ? ""
          : this.$store.commit(
              "authentication/setPanErrorMessage",
              "Please Enter Valid PAN Number"
            );
            if(this.whichStep == "Unblock"){
              
              this.email && /.+@.+\..+/.test(this.email)  ? this.emailErrorMessage = '' : this.emailErrorMessage = 'Please Enter Valid Email Id'
            }
      }
    },

    vaildateAuthorize() {
      // let user = {
      //   userId: this.getUserId,
      //   vendor: this.$store.state.ssoQuery.vendor,
      //   callBackUrl: this.$store.state.ssoQuery.redirectUrl,
      // };
      let user = {
        userId: this.getUserId,
        vendor: this.$store.state.ssoQuery.appcode,
      };
      this.$store.dispatch("authentication/authorizeVenfor", user);
    },

    vailDateResetPassword() {
      if (!!this.currentPass && !!this.newPass && !!this.confirmPass) {
        var user = this.getUserId;
        let jsonObj = {
          oldPassword: this.currentPass,
          newPassword: this.confirmPass,
          preLogin: "Y",
          userId: user,
          userSessionID: this.userSessionId,
        };
        this.newPass == this.confirmPass
          ? this.$store.dispatch("authentication/resetPassword", jsonObj)
          : (this.confirmPassErrorMessage =
              "Confirm Password does not match New Password");
        this.resetPassAndMpin();
      } else {
        !!this.currentPass
          ? ""
          : (this.currentPassErrorMessage =
              "Please Enter your Current Password");
        !!this.newPass
          ? ""
          : (this.newPassErrorMessage = "Please Enter your New Password");
        !!this.confirmPass
          ? ""
          : (this.confirmPassErrorMessage =
              "Please Enter your Confirm Password");
      }
    },

    toggleFieldTextType(val) {
      for (let item of this.selectedArray) {
        if (val.sindex == item.sindex) {
          val.fieldTextType = !val.fieldTextType;
          val.passwordFieldType =
            val.passwordFieldType === "password" ? "text" : "password";
        }
      }
    },

    checkBox(e, val) {
      if (e.target.checked === true) {
        if (this.counter < 5) {
          val.checked = true;
          for (let item of this.userQuestions) {
            if (item.sindex == val.sindex) {
              this.selectedArray.push(item);
            }
          }
          this.counter++;
        } else {
          e.target.checked = false;
          val.checked = false;
          errorHandling.toaster(
            "",
            "danger",
            "You Already Selected Five Questions",
            3000
          );
        }
      } else if (this.counter > 0) {
        this.counter--;
        for (let item of this.selectedArray) {
          if (item.sindex == val.sindex) {
            const index = this.selectedArray.indexOf(item);
            if (index !== -1) {
              this.selectedArray.splice(index, 1);
            }
          }
        }
      }
    },

    vaildateResetQues() {
      var checkCount = 0;
      var res = "";
      for (let item of this.selectedArray) {
        item.value != "" ? (checkCount = checkCount + 1) : "";
        res += item.sindex + "|" + item.value + "|";
      }

      let json = {
        userId: this.getUserId,
        ques_Ans: res.substring(0, res.length - 1),
      };
      checkCount > 0 && checkCount < 5
        ? errorHandling.toaster(
            "",
            "danger",
            "Please Fill a selected Questions",
            3000
          )
        : "";
      checkCount == 5
        ? this.$store.dispatch("authentication/saveSecQues", json)
        : "";
      this.resetPassAndMpin();
    },
    keyPressAlphaNumeric(event) {
      var inp = String.fromCharCode(event.keyCode);

if(event.keyCode != 13){
      if (/[a-zA-Z0-9]/.test(inp)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
}
    },
  },

  watch: {
     resetStep: function(){
      
      if(this.resetStep && this.resetStep == 'OTP'){
        this.resetPassAndMpin()
        this.$store.commit("authentication/setResetStep", null);
      }
    },
    currentPass: function () {
      this.currentPassErrorMessage = "";
    },
    newPass: function () {
      this.newPassErrorMessage = "";
    },
    confirmPass: function () {
      this.confirmPassErrorMessage = "";
    },
    passwordAndMpinErrorMessage: function () {
      this.passwordAndMpinErrorMessage != "" ? (this.newPassAndMpin = "") : "";
    },
    forgetUserErrorMessage: function () {
      this.forgetUserErrorMessage != "" ? (this.forgetUser = "") : "";
    },
    panErrorMessage: function () {
      this.panErrorMessage != "" ? (this.panNumber = "") : "";
    },
    panNumber: function (value) {
      value ? this.$store.commit("authentication/setPanErrorMessage", "") : "";
      value
        ? this.$store.commit("authentication/setUnblockErrorMessage", "")
        : "";
    },
    forgetUser: function (value) {
      value
        ? this.$store.commit("authentication/setForgetUserErrorMessage", "")
        : "";
      value
        ? this.$store.commit("authentication/setUnblockErrorMessage", "")
        : "";
    },
    email: function (value){
      value ? this.emailErrorMessage = "" : ''
    },
    emailErrorMessage: function (value){
      value ? this.email = '' : ''
    },
    securityAndCreateMpinErrorMessage1: function () {
      this.securityAndCreateMpinErrorMessage1 != ""
        ? (this.secQuesOneAndNewMpin = "")
        : "";
    },
    securityAndCreateMpinErrorMessage2: function () {
      this.securityAndCreateMpinErrorMessage2 != ""
        ? (this.secQuesTwoAndConMpin = "")
        : "";
    },
    unblockErrorMessage: function () {
      if(this.unblockErrorMessage != ""){
       this.email = this.panNumber =  this.forgetUser = ""
      }
    },
    questsErrorMessage: function () {
      this.questsErrorMessage != "" ? (this.secQuesOneAndNewMpin = "") : "";
      this.questsErrorMessage != "" ? (this.secQuesTwoAndConMpin = "") : "";
    },
  },
  computed: {
    userId: {
      get: function () {
        return this.$store.state.userId;
      },
      set: function (newValue) {
        this.$store.commit("authentication/setUserId", newValue.toUpperCase());
      },
    },
    whichStep: {
      get: function () {
        if (this.$store.state.authentication.whichStep == '"userId"') {
          this.$nextTick(() => this.$refs.user.focus());
        } else if (
          this.$store.state.authentication.whichStep == "Password" ||
          this.$store.state.authentication.whichStep == "M-Pin"
        ) {
          this.$nextTick(() => this.$refs.mpin.focus());
        } else if (
          this.$store.state.authentication.whichStep == "2FA" ||
          this.$store.state.authentication.whichStep == "createMpin"
        ) {
          this.$nextTick(() => this.$refs.twofa.focus());
          this.reset2FA();
        } else if (this.$store.state.authentication.whichStep == "reset") {
          this.$nextTick(() => this.$refs.reset.focus());
        } else if (
          this.$store.state.authentication.whichStep == "Unblock" ||
          this.$store.state.authentication.whichStep == "Forget"
        ) {
          this.$nextTick(() => this.$refs.unblock.focus());
        }
        return this.$store.state.authentication.whichStep;
      },
      set: function (value) {},
    },
    ...mapState("authentication", [
      "userQuestions",
      "passwordAndMpinErrorMessage",
      "userSessionId",
      "forgetUserErrorMessage",
      "securityAndCreateMpinErrorMessage1",
      "securityAndCreateMpinErrorMessage2",
      "panErrorMessage",
      "questsErrorMessage",
      "unblockErrorMessage",
      "userIdLoader",
      "passOrMpinLoader",
      "createOr2faLoader",
      "forgetOrUnblockLoader",
      "authorizeLoader",
      "resetLoader",
      "reset2faLoader",
      "getTimer",
      "resetStep",
      "vendorDetails"
    ]),
    ...mapGetters("authentication", [
      "getUserId",
      "getUserSession",
      "getUserSessionDto",
    ]),
  },
  mounted() {
    var checkStep = JSON.parse(localStorage.getItem("whichStep"));
    !!checkStep && checkStep != "undefined"
      ? this.$store.commit("authentication/setWhichStep", checkStep)
      : "";
    var checkQues = JSON.parse(localStorage.getItem("secQuestion"));
    !!checkQues && checkQues != "undefined"
      ? this.$store.commit("authentication/setSecQuestionRefresh", checkQues)
      : "";
    var checkIsMpin = JSON.parse(localStorage.getItem("isMpin"));
    checkIsMpin != undefined && checkIsMpin != "undefined"
      ? this.$store.commit("authentication/setIsMpin", checkIsMpin)
      : "";
    var checkAuthcode = JSON.parse(localStorage.getItem("authcode"));
    !!checkAuthcode && checkAuthcode != "undefined"
      ? this.$store.commit("authentication/setRedirectUrl", checkAuthcode)
      : "";
    this.selectedArray = [];
  },
  created(){
    let theme = localStorage.getItem('dark_theme') ? localStorage.getItem('dark_theme') : "false"
    theme == "true" ? this.$vuetify.theme.dark = true : this.$vuetify.theme.dark = false
    let themeVal = theme == "true"  ? 'dark' : 'light'
     this.$store.commit('setSelectedTheme' , themeVal)
  }
};
</script>